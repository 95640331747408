@import "~antd/dist/antd.css";

body {
  margin: 0;
  padding: 0;
  font-family: Muli, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 14px;
}

h1,
h2,
h3 {
  font-family: Share Tech Mono, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: var(--light-navy);
}

a:hover {
  color: var(--vibrant-blue);
}

::selection {
  color: #fff;
  background: var(--vibrant-blue);
}

:root {
  --light-navy: #13227a;
  --light-navy-0: rgba(19, 34, 122, 0);
  --light-vibrant-blue: #3355ef;
  --vibrant-blue: #1838f1;
  --vibrant-blue-5: rgba(24, 56, 241, 0.05);
  --vibrant-blue-10: rgba(24, 56, 241, 0.1);
  --bluey-grey: #848cb6;
  --light-grey-blue: #a3a9c8;
  --pale-grey: #f6f7fc;
  --pale-grey-two: #fbfbff;
  --pale-grey-35: rgba(231, 231, 255, 0.35);
  --pale-grey-50: rgba(246, 247, 252, 0.5);
  --pale-grey-three: #e7e7ff;
  --dark-indigo: #0b1448;
  --dark-indigo-10: rgba(11, 20, 72, 0.1);
  --light-periwinkle: #dddfec;
  --light-periwinkle-0: rgba(206, 206, 224, 0);
  --light-periwinkle-20: rgba(206, 206, 224, 0.2);
  --watermelon: #f74555;
  --watermelon-30: rgba(247, 69, 85, 0.3);
  --dusty-orange: #f08434;
  --dusty-orange-30: rgba(240, 132, 52, 0.3);
  --sun-yellow: #f9d022;
  --sun-yellow-30: rgba(249, 208, 34, 0.3);
  --aqua-marine: #2aefb2;
  --aqua-marine-35: rgba(42, 239, 178, 0.35);
  --bright-cyan: #48f0e2;
  --seaweed: #1bd17d;
  --dark-mint-green: #22ba75;
  --purpley-75: rgba(115, 84, 250, 0.75);
  --purply-blue: #7913fe;
  --purpleish-blue: #6b49ff;
  --blurple: #4e2ce0;
  --white: #ffffff;
  --black: #000000;
}
